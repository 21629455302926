@.newsListHandler = ->
    $(document).ready(->
        bindShowMoreButton()
    )

    bindShowMoreButton = ->
        loading = false
        form = $('.news-box form')
        buttonNews = $('.news-box .button-news')
        loader = $('.news-box .loader')
        newsRow = $('.news-box .col3-box .row')
        buttonNews.on('click', ->
            return if loading
            loading = true
            buttonNews.hide()
            loader.show()
            $.ajax({
                url: form.attr('action'),
                method: form.attr('method'),
                data: form.serialize(),
                dataType: 'json',
                success: (data) ->
                    form.find('input[name="news-count"]').val(data.new_news_count)
                    newsRow.append(data.template)
                    loading = false
                    loader.hide()
                    if data.show_more_button == true
                        buttonNews.show()
            })
        )

